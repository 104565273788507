<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Thermite reactions are highly exothermic reactions between a metal oxide and a fine metal
        powder. The exothermic nature of the reaction is used for welding metal parts such as
        railway tracks. One hypothetical reaction of this type is:
      </p>

      <p class="mb-5 pl-10">
        <chemical-latex content="A2O3(s) + 2 B(s) -> B2O3(s) + 2 A(s)" />
      </p>

      <p class="mb-3">
        If the surroundings absorb
        <number-value :value="qsurr" unit="\text{kJ/mol}" />
        of heat due to the reaction, answer the following questions using the table of data provided
        below (determined at 298 K):
      </p>

      <v-simple-table style="width: 300px" class="mb-6 pl-8">
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Molecule}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{S}^\circ\text{ (J K}^{-1}\text{mol}^{-1})$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\ce{A2O3(s)}$" />
            </td>
            <td style="text-align: center">
              <number-value :value="SA2O3" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\ce{B(s)}$" />
            </td>
            <td style="text-align: center">
              <number-value :value="SB" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\ce{B2O3(s)}$" />
            </td>
            <td style="text-align: center">
              <number-value :value="SB2O3" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\ce{A(s)}$" />
            </td>
            <td style="text-align: center">
              <number-value :value="SA" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-5">
        a) Determine
        <stemble-latex content="$\Delta\text{S}_{\text{sys}}$" /> for the reaction at 298 K.
      </p>

      <calculation-input
        v-model="inputs.deltaSsys"
        class="mb-2"
        prepend-text="$\Delta\text{S}_{\text{sys}}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />

      <p class="mb-5">
        b) What is the value of
        <stemble-latex content="$\Delta\text{S}_{\text{univ}}$" /> for the reaction at 298 K.
      </p>

      <calculation-input
        v-model="inputs.deltaSuniv"
        class="mb-2"
        prepend-text="$\Delta\text{S}_{\text{univ}}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question295a',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        deltaSsys: null,
        deltaSuniv: null,
      },
    };
  },
  computed: {
    qsurr() {
      return this.taskState.getValueBySymbol('qsurr').content;
    },
    SA2O3() {
      return this.taskState.getValueBySymbol('SA2O3').content;
    },
    SB2O3() {
      return this.taskState.getValueBySymbol('SB2O3').content;
    },
    SA() {
      return this.taskState.getValueBySymbol('SA').content;
    },
    SB() {
      return this.taskState.getValueBySymbol('SB').content;
    },
  },
};
</script>
